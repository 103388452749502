<template lang="pug">
v-app.acgtransparent(:style="acgMicroApp")
  v-main.acgtransparent(:style="acgMicroApp")
    Index
</template>

<script>
import Index from "./components/Index";

export default {
  name: "App",

  components: {
    Index,
  },

  data: () => ({
    acgMicroApp: {
      background: "transparent"
    },
  }),
};
</script>
<style>
.acgtransparent {
  background-color: transparent !important;
}
.v-application {
  background-color: transparent !important;
}
.theme--light.v-application {
  background: transparent !important;
}
.theme--light.v-sheet,
.theme--light.v-card {
  background-color: transparent !important;
}
.v-application--wrap {
  min-height: 0 !important;
}
</style>