<template lang="pug">
v-card.acgtransparent(flat)
  form.pb-8(
    method="post",
    action="https://public.govdelivery.com/accounts/VAARLINGTON/subscribers/qualify",
    v-for="topic in topics",
    :key="topic.topic_id"
  )
    input#topic_id(type="hidden", name="topic_id", v-model="topic.topic_id")
    p.pb-0.mb-0 To subscribe to {{ topic.displayName }}:
    v-text-field#email.pt-2.mt-0(
      name="email",
      v-model="email",
      label="provide your email address here"
    )
    v-btn#commit.white--text(name="commit", type="submit", color="info") Submit
</template>

<script>
export default {
  props: {
    topics: Array,
  },
  components: {
    //
  },
  methods: {
    //
  },
  data() {
    return {
      //
    };
  },
};
</script>
<style scoped>
  .acgtransparent {
    background-color: transparent !important;
  }
</style>