<template lang="pug">
  v-container
    div(v-if="isLoaded")
      div(v-if="!isConfigured")
        div(v-if="isContentPreview")
          h4 This appears to be Preview
          p You MUST be on the published page in order to configure this.
          p You cannot configure this in preview (the preview page is not the correct URL).
        div(v-else)
          Setup
      div(v-else)
        NewSubscribe(
          v-bind:topics="topics"
        )
    div(v-else)
      v-progress-circular(indeterminate)
</template>

<script>
import service from "../data/service";
import Setup from './Setup.vue';
import NewSubscribe from './NewSubscribe.vue';

export default {
  components: {
    Setup,
    NewSubscribe,
  },
  data() {
    return {
      isLoaded: false,
      isConfigured: true,
      isContentPreview: false,
      topics: [],
    }
  },
  async created() {
    let topicsPromise = service.getTopics();
    await topicsPromise.then(async (response) => {
      let resposeData = await response.json();
      this.topics = resposeData;
      if (resposeData.length == 0) {
        this.isConfigured = false
      } 
      this.isLoaded = true;
    })
    let pathName = window.location.pathname;
    if (pathName.indexOf('ContentDispatcher') > 0) {
      this.isContentPreview = true;
    }
  }
}
</script>
