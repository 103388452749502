const saveCosmosDb = 'https://arl-govdeliverytopics-logicapp.azurewebsites.net/api/SetTopic';
const getCosmosDb = 'https://arl-govdeliverytopics-logicapp.azurewebsites.net/api/GetTopic';
const service = {
  getTopics() {
    let cy = new Date().getFullYear();
    return fetch(getCosmosDb + "?cy=" + cy + "&pageUrl=" + window.location.pathname, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  },
  saveToCosmosDb(topic_id, displayName) {
    let body = {
      displayName: displayName,
      topic_id: topic_id,
      pageUrl: window.location.pathname,
      cy: new Date().getFullYear()
    };
    return fetch(saveCosmosDb, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
};

export default service;