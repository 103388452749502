<template lang="pug">
v-card(flat)
  div(v-if="success")
    Success
  div(v-else)
    p You MUST be on the published page in order to configure this.
    p You cannot configure this in preview (the preview page is not the correct URL).
    v-text-field(
      label="Please insert the display name you'd like for your topic",
      v-model="govDeliveryDisplayName"
    )
    v-text-field(
      label="Please insert the GovDelivery Topic ID",
      v-model="govDeliveryTopicId"
    )
    v-card-actions
    v-btn.white--text(
      color="#e38410",
      @click="saveToCosmosDb()",
      skipSuccess,
      :right="true",
      :absolute="true"
    ) Save
</template>

<script>
import service from "../data/service";
import Success from "./Success.vue";

export default {
  components: {
    Success,
  },
  methods: {
    saveToCosmosDb() {
      console.log({ topic_id: this.govDeliveryTopicId });
      let savingRes = service.saveToCosmosDb(this.govDeliveryTopicId, this.govDeliveryDisplayName);
      savingRes.then((response) => {
        if (response.ok) {
          this.success = true;
        } else {
          alert("This didn't work. Please try again later.");
        }
      })
    },
  },
  data() {
    return {
      govDeliveryDisplayName: undefined,
      govDeliveryTopicId: undefined,
      success: false,
    };
  },
};
</script>