import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { 
      customProperties: true,
      cspNonce: 'dQw4w9WgXcQ'
    },
    themes: {
      light: {
        info: '#e38410',
        background: "transparent",
      }
    }
  }
});
